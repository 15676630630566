<template>
  <v-app
    app
  >
    <v-app-bar
      prominent
      short
      fixed
      app
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <router-link
              to="/"
            >
              <v-img
                contain
                max-height="112"
                :src="image_src"
              />
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer />
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      app
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            v-for="(link,index) in linkTitle"
            :key="index"
            link
            :to="link.to"
          >
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer
      :padless="padless"
      app
    >
      <v-container>
        <v-row>
          <v-spacer />
          <v-col>
            <v-img
                contain
                max-height="112"
                :src="footerImager_src"
              />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      offset: true,
      padless: true,
      //image_src: require('./assets/kaisyulogo_03.gif'),
      //footerImager_src: require('./assets/ssl_04.gif'),
      linkTitle: [
        {
          title: 'トップページ',
          to: '/'
        },
        {
          title: '中島様',
          to: '/a'
        },
        {
          title: '阿部様',
          to: '/b'
        },
        {
          title: '種市様',
          to: '/c'
        },
        {
          title: '西村様',
          to: '/d'
        }
      ],
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/'
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire'
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'パソコン回収.net'
    }
  }
}
</script>

<style scoped>

.link--active{
  font-size:20px;
}

</style>